import React, { useEffect, useState } from 'react'
import Layout from '../components/layout/layout'
import styled from 'styled-components'

import PageTop from '../components/common/PageTop'
import Title from '../components/common/Title'
import PageComponent from '../components/common/PageComponent'

import CompanyInfo from '../components/company/CompanyInfo'
import SupremeInfo from '../components/company/SupremeInfo'

const pageComponentStyle = {
    background: 'var(--white-color-1)',
    padding: 0
}
const containerStyle = {
    maxWidth: '1000px',
    margin: '0 auto',
}

const CompanyStyle = styled.section`
    padding: 20px 0;
    font-family: var(--gothic);
    font-weight: 300;
    .company-nav {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        background: var(--white-color-2);
        border-radius: 5px;
        border: solid 1px var(--gray-color-3);
        padding: 20px;
        .nav {
            padding: 10px 15px;
            font-size: 14px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            svg {
                transition: var(--transition-timing);
                width: 10px;
                height: 10px;
                margin-right: 5px;
            }
            &:hover {
                color: var(--gray-color-3);
                svg {
                    fill: var(--gray-color-3);
                    transform: translateX(-5px);
                }
            }
        }
    }
    .company-descriptions {
        .company-description {
            margin: 50px 0;
            &:first-child {
                margin-top: 0;
            }
        }
    }
`

function CompanyPage() {
    const [isLoaded, setIsLoaded] = useState(false)
    const [width, setWidth] = useState();
    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        if (!isLoaded) {
            setIsLoaded(true)
            handleResize();
        }
        window.addEventListener("resize", handleResize);
        return () => {
          window.removeEventListener("resize", handleResize);
        };
    }, []);
    return (
        <Layout pageTitle="会社概要">
            <PageTop title='COMPANY' />
            <PageComponent styles={pageComponentStyle} containerStyles={containerStyle}>
                <Title title='会社概要' subtitle='シュプリームテクノロジーと株式会社マーチについて' />
                <CompanyStyle>
                    <div className='company-descriptions'>
                        <div className='company-description description-1'>
                            <CompanyInfo />
                        </div>
                        <div className='company-description description-1'>
                            <SupremeInfo infoNum={1} />
                        </div>
                        <div className='company-description description-1'>
                            <SupremeInfo infoNum={2} />
                        </div>
                    </div>
                </CompanyStyle>
            </PageComponent>
        </Layout>
    )
}

export default CompanyPage

