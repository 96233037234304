import React from 'react'
import styled from 'styled-components'

const description1 = [
    { 
        title: 'Supreme Technologyについて',
        description: 
            [
                'シュープリーム・テクノロジー社は、25年に及ぶモータースポーツ界でのエンジニアとしての実績で知られてきた鬼才、ニコラ・ブラガノーロ（NICOLA BRAGAGNOLO）が設立したパフォーマンス・テクノロジー（Performance Technology）社をその前身としています。2018年、ニコラは家族の事情により、それまで築いてきた「パフォーマンス・テクノロジー」というブランドを、気鋭の経営陣が率いる「シュープリーム・テクノロジー社」に譲渡し、現在に至ります。',
                '「パフォーマンス・テクノロジー」社は、とりわけブレーキやサスペンションといったコンポーネントに関して、25年にも及ぶ二輪業界のモータースポーツ分野で、革新的な技術を提供し続ける企業として、APRILLIA, DUCATI, HONDA, YAMAHA,SUZUKI, KTMと言った多くの一流企業とのコラボレーションを成功に導いてきました。',
                '「シュープリーム・テクノロジー」社は、このブランドの精神を引き継ぎ、豊富な加工設備や航空学の先進技術を取り入れ、こうした先進技術を自社製品に還元し、プロから一般のユーザーまで商品を提供してまいります。'
            ]
    }
]
const description2 = [
    { 
        title: 'OVER SUSPENSION PROJECTについて', 
        description: 
            [
                'オーバーサスペンション・プロジェクトの誕生は、「パフォーマンス・テクノロジー」がモータースポーツ界と深くかかわっていた時代に遡ります。サスペンションやダンパー技術が進化する一方で、サスペンション（ショックアブソーバー）では吸収しきれない、想定外の応力や振動が発生してしまうことに注目したことから始まりました。車体の動きとサスペンションユニットの動作を解析した結果、こうした想定外の応力や振動の発生は、ショックアブソーバーでは吸収しきれない瞬間的な動作や、ダンパー機構本体から発生する振動などがその原因ではないか、と考えました。',
                '一般に、技術的には、車体に搭載されているサスペンション機構は、「吊り下げられていない質量」、すなわち車体と接続しているものと定義されています。例えば自動車やオートバイのように、サスペンション機構が車両本体と接続し、かつサスペンションの先（この場合タイヤ）が地面と絶えず接触している以上、サスペンションはあくまでも車両本体の質量から切り離されていない、つまり「吊り下げられてない」質量、ととらえられているのです。サスペンションとは、たとえ最先端の電子制御機構が組み込まれたものであっても、妥協の産物にすぎません。',
                '絶えることなく変化し続ける路面状況を確実にとらえ続け、常時均一のトラクション（ロードホールディング）を提供し続けることは、サスペンションという動的な機構の宿命ともいえる共振の発生から逃れることができないことも相まって、事実上困難であり、その妥協点をどこに置くべきなのか、がセッティングの肝ともいえるのが実情です。ここに、さらにタイヤという問題が加わります。タイヤは、ゴムという弾性の塊で製造されているうえ、内部に外的な要因で変化しやすい空気を収められて成立しています。負荷をかけられればタイヤは変形し、それが解放されることでスプリングの一種といってもよい機能を同時に果たすわけですが、その戻る力を車体として制御することは事実上不可能です。すなわち、タイヤがあることで、サスペンションで制御しきれない不安定な要素がさらに増加してしまう、ということになるのです。',
                'オーバーサスペンション（OVER SUSPENSION）は、こうした、車両が制御しきれない細かな振動や予測の困難な外界からの作用を抜本的に解決、もしくは劇的に減少させる仕組みといっていいでしょう。その結果、車体のポテンシャルを最大限引き出すことが可能になります。'
            ]
    }
]
const description3 = [
    { 
        title: '株式会社マーチについて', 
        description: [
            '株式会社マーチは二輪車及び二輪車のパーツ・アクセサリーの輸出入をおこなっており、シュプリーム・テクノロジー社製オーバーサスペンションの総輸入元です。'
        ]
    }
]
const SupremeInfoStyle = styled.div`
    font-family: var(--gothic);
    .supreme-description {
        padding-bottom: 32px;
        .title {
            font-size: 16px;
            font-weight: 600;
            line-height: 2;
            padding-bottom: 5px;
            color: #444;
            color: var(--gray-color-2);
        }
        .description {
            color: var(--gray-color-2);
            font-size: 14px;
            line-height: 1.5;
            letter-spacing: 0.05em;
            padding: 5px 0;
        }
    }
`

function SupremeInfo({infoNum}) {
    const descriptions = infoNum === 1 ? description1 : infoNum === 2 ? description2 : description3
    return (
        <SupremeInfoStyle>
            {descriptions.map((d, index) => {
            return (
                <div className='supreme-description' key={index}>
                    <h2 className='title'>{d.title}</h2>
                    {d.description.map(paragraph => (
                        <p className='description'>　{paragraph}</p>
                    ))}
                </div>
            )})}
        </SupremeInfoStyle>
    )
}

export default SupremeInfo
